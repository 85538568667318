
.Testimonials-Page{
    background-color:rgba(255, 234, 234);
    
    font-family: 'Standard';
    
}

.Testimonials-Page .Title{
    grid-area: one;
    color: rgba(112, 128, 144,1);
    position: relative;

    
}

.Testimonials-Page .Testimonial-Container{
    color: rgba(112, 128, 144,1);
}

.Testimonials-Page .Testimonial-Attribution{
    color: #FFB0B0;
    right:0px;
    position: absolute;
}
.Testimonials-Page .First-Testimonial{
    grid-area: two;
}
.Testimonials-Page .Second-Testimonial{
    grid-area: three;
}

.Testimonials-Page .Third-Testimonial{
    grid-area: four;
}
.Testimonials-Page .Title span{
    font-size: 90px;
    position: absolute;
    right: 0px;
}

.Testimonials-Page .Content-Area{
    grid-template-areas: 
    "one"
    "two" 
    "three" 
    "four" ;    

    display:grid;
    height:80vh;
    padding-top: 10vh;
    grid-template-rows: 1fr 1fr 1fr 1fr ;
    grid-template-columns: 1fr;
    padding-left: 10px;
    padding-right: 10px;
    

}

.Testimonials-Page .Attribution{
    color: #FFB0B0;
    position: absolute;
    right:0px;
}
.Testimonials-Page .Testimonial-Container{
    position: relative;
    font-size:19px;
}
@media (max-width: 450px){
    .Testimonials-Page .Title span {
       font-size:70px;
    }

    .Testimonial-Container{
        padding-bottom: 20px;
        align-self: center;

    }
}
@media (max-width: 900px){
    .Testimonials-Page{
        height:fit-content;
        padding-bottom:25vh;
    }

    .Testimonials-Page .Content-Area{
        height:fit-content;
    }
}

@media (min-width: 900px){
    .Testimonials-Page .Accent-Line{
        background-color:#FFB0B0;
        height:20px;
        
    }
    

}