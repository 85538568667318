.Projects-Page{
    background-color: rgba(112, 128, 144,1);
    height:fit-content;
    font-family: 'Standard';

}
.Projects-Page .Content-Area{
    grid-template-areas: 
    "one-one one-two one-three one-four"
    "two-one two-two two-three two-four"
    "three-one three-two three-three three-four"
    "four-one four-two four-three four-four"
    "five-one five-two five-three five-four";
    display:grid;
    height:fit-content;    
}

.Projects-Page .Content-Area{
    grid-template-areas: 
    "one"
    "two"
    "three"
    "four"
    "five";
    display:grid;
}


.Project-Panel{ 
    position: relative;
    width:90vw;
    margin-left:5vw;
    margin-bottom: 15px;
}

.Project-Panel .Project-Content{
    background-color: white;
    height:100%;
    width:100%;
}

.Project-Panel .Project-Content .Project-Picture{
    height:50%;
}

.Project-Panel.Project-Two .Project-Content .Project-Picture{
    background: url(../../../public/AcademicPromotions.png) no-repeat fixed 100% 100%;
    background-size: cover;
}

.Project-Panel.Project-One .Project-Content .Project-Picture{
    background: url(../../../public/Foodbank.png) no-repeat fixed 100% 100%;
    background-size: cover;
}
.Project-Panel.Project-Three .Project-Content .Project-Picture{
    background: url(../../../public/Satellite.png) no-repeat fixed 100% 100%;
    background-size: cover;
}

.Project-Panel.Project-Four .Project-Content .Project-Picture{
    background: url(../../../public/Fastlaw.png) no-repeat fixed 100% 100%;
    background-size: cover;
}


/* .Project-Panel .Project-Content .Project-Picture img{
    object-fit: cover;
} */

.Project-Panel .Project-Content .Project-Desc{
    height:50%;
    position:relative;
}

.Project-Panel .Project-Content .Project-Desc .Tools-List{
    height:100%;
    width:40px;
    position: absolute;
}

.Project-Panel .Project-Content .Project-Desc .Tools-List img{
    width:40px;
}

.Project-Panel .Project-Content .Project-Desc .Project-Title{
    position: absolute;
    left:40px;
    height:50px;
    width:calc(100% - 40px);
    color: #FFB0B0;
    font-size: 50px;
    line-height:50px;

}
.Project-Panel .Project-Content .Project-Desc .Project-Summary{
    position: absolute;
    left:40px;
    top:50px;
    height:calc(100% - 90px);
    width:calc(100% - 40px);
    font-size: 24px;
}
.Project-Panel .Project-Content .Project-Desc .Project-Summary .Accented{
    color: #FFB0B0;
}

.Project-Panel .Project-Content .Project-Desc .Project-Partner{
    position: absolute;
    left:40px;
    top:calc(50px +  100% - 90px);
    height:40px;
    width:calc(100% - 40px);
    font-size: 30px;
    color: #FFB0B0;
}
.Project-Panel .Project-Content .Project-Desc .Project-Partner .Partner-Tag{

    font-size: 30px;
    color: #FFB0B0;
    right: 50px;
    position: absolute;
}
.Project-Panel .Project-Content .Project-Desc .Project-Partner img{
    height:40px;
    right: 0px;
    position: absolute;
}


.Projects-Page .Title{
    grid-area:one;
    color: rgba(255, 234, 234);

}
.Projects-Page .Project-One{
    grid-area: two;
    height:75vh
}
.Projects-Page .Project-Two{
    grid-area: three;
    height:75vh;
}
.Projects-Page .Project-Three{
    grid-area: four;
    height:75vh

}
.Projects-Page .Project-Four{
    grid-area: five;
    height:75vh

}

.Projects-Page .Title span{
    font-size: 100px;
    margin-left:5vw;
}




@media (max-width: 450px){
    .Projects-Page .Title span {
       font-size:70px;
    }
}

@media (min-width: 900px){

    .Project-Panel{ 
        position: relative;
        padding-left:5px;
        padding-right:5px;
        width:96.5%;
        margin:auto;

    }
    .Projects-Page .Project-Panel{
        height:100%;
    }
    .Accent-Line {
        border-bottom: 20px solid rgba(255, 234, 234);
        position: absolute;
     
    }
    
    .Accent-Line-Top {
        top: 40px;
        width: 45vw;
    }
    
    .Accent-Line-Bottom {
        bottom:40px;
        width: 45vw;
        right:0;
    }
    .Projects-Page{
        height:100vh;

    }
    .Projects-Page .Content-Area{
        grid-template-areas: 
        "one-one one-two one-three one-four"
        "two-one two-two two-three two-four"
        "three-one three-two three-three three-four"
        "four-one four-two four-three four-four"
        "five-one five-two five-three five-four";
        display:grid;
        height:80vh;    
        margin-top: 10vh;
    }

    .Projects-Page .Title{
        grid-area:one-three/one-three/one-four/one-four;
        font-size: 100px;
        position: relative;
    }

    .Projects-Page .Title span{
        font-size: 100px;
        position: absolute;
        right: 0px;
    }


    .Projects-Page .Project-One{
        grid-area: two-one/two-one/five-one/five-one;
    }
    .Projects-Page .Project-Two{
        grid-area: two-two/two-two/five-two/five-two;
    }
    .Projects-Page .Project-Three{
        grid-area: two-three/two-three/five-three/five-three;
    }
    .Projects-Page .Project-Four{
        grid-area: two-four/two-four/five-four/five-four;
    }
}
