.Landing-Page{
    background-color: rgba(255, 234, 234);
    font-family: 'Standard';
}

.Landing-Page .Content-Area{
    grid-template-areas: 
    "one-one one-two" 
    "two-one two-two" 
    "three-one three-two" 
    "four-one four-two" 
    "five-one five-two" 
    "six-one six-two";
    display:grid;
    height:80vh;
    padding-top: 10vh;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding-left: 10px;
    padding-right: 10px;

}

.Landing-Page .Profile-Picture{
    grid-area: one-one/one-one/two-one/one-one;
    position: relative;
}

.Landing-Page .Profile-Picture img{
    position: absolute;
    bottom:0px;
    width:100%;
    height:auto;
}
.Landing-Page .Title{
    grid-area: one-two/one-two/two-two/one-two;
    color: #FFB0B0;
    font-size: 150px;
    position: relative;
    line-height: 95px;
}

.Landing-Page .Title span{
    position: absolute;
    bottom:0px;

}

.Landing-Page .Job-Title{
    color: #FFB0B0;
    font-size: 11vw;

    grid-area: three-one/three-one/three-two/three-two;
}
.Landing-Page .Github-Display{
   /* display: none; */
   grid-area: four-one/four-one/four-two/four-two;


}

.Landing-Page .Github-Display img{
    width:95%;
    background-color: rgba(112, 128, 144,0.5);
    padding:5px;

}

.Landing-Page .Social-Bar{
    grid-area: five-one/five-one/five-two/five-two;
    
    display: flex;
    justify-content: space-between;

}
.Landing-Page .Projects-Link{
    color: #FFB0B0;
    grid-area: six-one/six-one/six-two/six-two;
    font-size: xx-large;
    position: relative;
}

.Landing-Page .Projects-Link span{
    cursor: pointer;
}

.Landing-Page .Projects-Link span:hover{
    color: rgba(112, 128, 144,0.5);
}
.Landing-Page .Projects-Link span:nth-child(2){
    position: absolute;
    top:0px;
    right:10px;
}

.Landing-Page .Projects-Link span:nth-child(1){
    position: absolute;
    top:0px;
    left:10px;

}

.Landing-Page .About-Me-Link{
    background-color:coral;
    grid-area: five-two;
}

.Landing-Page .Social-Bar img {
    width: 80px;
    cursor:pointer;
}


@media (min-width: 786px){
    .Landing-Page .Content-Area{
        grid-template-areas: 
        "one-one one-two one-three one-four one-five one-six" 
        "two-one two-two two-three two-four two-five two-six"
        "three-one three-two three-three three-four three-five three-six"
        "four-one four-two four-three four-four four-five four-six"
        "five-one five-two five-three five-four five-five five-six";
        /* "six-one six-two six-three six-four six-five six-six"; */
        display:grid;
        height:100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 2fr;    
        padding-left: 0px;
        padding-right: 0px;
        max-width:786px;

    }
    
    .Landing-Page .Profile-Picture{
        grid-area: one-one/one-one/one-two/one-two ;
    }
    .Landing-Page .Profile-Picture img{
        width:100%;
        height:auto;
    }
    .Landing-Page .Title{
        grid-area: one-three/one-three/one-three/one-six;
    }
    .Landing-Page .Job-Title{
        grid-area: two-one/two-one/two-two/two-six;
        font-size: 80px;
    }
    .Landing-Page .Github-Display{
        display: inline-block;
        grid-area: three-one/three-one/three-one/three-five;
    }

    .Landing-Page .Github-Display img{
        background-color: rgba(112, 128, 144,0.5);
        width:95%;
        padding:5px;
    }
    .Landing-Page .Projects-Link{
        grid-area: three-six/three-six/three-six/three-six;
    }
    .Landing-Page .Projects-Link span:nth-child(1){
        position: absolute;
        right:0px ;
        left:auto;

    }
    .Landing-Page .Projects-Link span:nth-child(1):after{
        content: " >";
    }
    .Landing-Page .Projects-Link span:nth-child(2){
        position: absolute;
        bottom:0px ;
        right:0px ;
        top:auto;

    }
    .Landing-Page .Projects-Link span:nth-child(2):after{
        content: " >";
    }

    .Landing-Page .Social-Bar{
        grid-area: four-one/four-one/four-one/four-three;
        display: inline-block;
    }
    .Landing-Page .Social-Bar img{
        width: 50px;
        margin-right: 10px;
    }
    
}

@media (min-width: 1200px){
    .Landing-Page .Content-Area{
        grid-template-areas: 
        "one-one one-two one-three one-four one-five one-six" 
        "two-one two-two two-three two-four two-five two-six"
        "three-one three-two three-three three-four three-five three-six"
        "four-one four-two four-three four-four four-five four-six"
        "five-one five-two five-three five-four five-five five-six";
        /* "six-one six-two six-three six-four six-five six-six"; */
        display:grid;
        height:100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 2fr;    
        padding-left: 0px;
        padding-right: 0px;
        max-width:1200px;


    }
    
    .Landing-Page .Profile-Picture{
        grid-area: one-one ;
    }
    .Landing-Page .Title{
        grid-area: one-two/one-two/one-two/one-three;
    }
    .Landing-Page .Job-Title{
        grid-area: two-one/two-one/two-two/two-four;
        font-size: 80px;
    }
    .Landing-Page .Github-Display{
        display: inline-block;
        grid-area: three-one/three-one/three-one/three-four;
    }

    .Landing-Page .Github-Display img{
        background-color: rgba(112, 128, 144,0.5);
        width:90%;
        padding:5px;
    }
    .Landing-Page .Projects-Link{
        grid-area: three-five/three-five/three-five/three-six;
    }
    .Landing-Page .Projects-Link span:nth-child(1){
        position: absolute;
        right:0px ;
        left:auto;

    }
    .Landing-Page .Projects-Link span:nth-child(1):after{
        content: " >";
    }
    .Landing-Page .Projects-Link span:nth-child(2){
        position: absolute;
        bottom:0px ;
        right:0px ;
        top:auto;

    }
    .Landing-Page .Projects-Link span:nth-child(2):after{
        content: " >";
    }

    .Landing-Page .Social-Bar{
        grid-area: four-one/four-one/four-one/four-three;
        display: inline-block;
    }
    .Landing-Page .Social-Bar img{
        width: 50px;
        margin-right: 10px;
    }
    
    
  }

