@font-face {
  font-family: "Standard";   /*Can be any text*/
  src: local("Staatliches"),
    url("./fonts/Staatliches-Regular.ttf") format("truetype");
}


.Section{
  height: 100vh;
  width: 100vw;
}


.Container {
  width: 100vw;
 
}

.App{
  overflow-x: hidden;
}

@media (min-width: 768px){
  .Container{
    width: 300vw;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
  }

  .App{
    overflow-y: hidden;

  }

  .Content-Area{
    max-width:1200px;
    height:90vh;
    
    margin: auto auto auto auto;
    margin-top:5vh;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

